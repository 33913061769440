import React from 'react';
import {Badge, Box, Heading, HStack, SimpleGrid, Text} from '@chakra-ui/react';

function SoftSkills() {
    return (
        <SimpleGrid columns={1} spacing={4} width="100%">
            <Box>
                <Heading size="sm">Project management</Heading>
                <Text color="gray.800" pt="5" pb="5">My first opportunities to work as a team leader and to manage
                    projects happened in 2014 when I was hired as a security analyst by Riscure B.V in the Netherlands.
                    I had to organize the communication and tasks between the client and the team members. Working on
                    these projects for almost six years was a very rich part of my career and I learned how to
                    efficiently organize tasks and how to integrate results to make the client happy.</Text>

                <Text color="gray.800" pb="5">I also learned that working on project management is not always selecting
                    exactly what you like. Also, it involves finding the best possible task for every team member. I
                    learned how to find paths to keep everybody motivated and how to find the fastest solution when
                    facing pressure situations.
                </Text>
            </Box>

            <Box>
                <Heading size="sm">Supervision and mentoring</Heading>
                <Text color="gray.800" pt="5" pb="5">In the past ten years, I had three main opportunities to develop
                    and apply my supervision and mentoring skills. During my experience in industry, besides working as
                    team leader in different customer projects, I was tasked with the supervision of internship
                    programs. Working with entry-level professionals seeking for industrial experience was a very rich
                    opportunity to develop my leadership skills. During almost six years, I had supervised at least
                    three interns per year.</Text>
                <Text color="gray.800" pb="5">In 2020, when I re-entered academia as a postdoctoral researcher, I was
                    focused on developing cutting-edge research on the domains of security and artificial intelligence
                    but also on improving my supervision skills. For three years, I have closely supervised master and
                    PhD students. In total, I have co-supervised and supervised fourteen master students and eight PhD
                    students from two different Dutch universities.</Text>
                <Text>These experiences helped to achieve further carrier goals as an assistant professor, in which the
                    supervision and mentoring are significant parts of my daily work.</Text>
            </Box>

            <Box>
                <Heading size="sm">Problem-solving and prototyping</Heading>
                <Text color="gray.800" pt="5" pb="5">Working on research and development for more than a decade, I
                    recognize that among my strongest skills are problem-solving and prototyping. In this context, I
                    learned how to efficiently transfer knowledge from academia to industry by incorporating research
                    papers into software solutions. Moreover, because I always worked on multi-disciplinary fields, I
                    learned how to combine solutions from different domains with efficient demonstrations. I can
                    recognize in myself that this has improved my creating thinking.</Text>
            </Box>

            <Box>
                <Heading size="sm">Communication and Teamwork</Heading>
                <Text color="gray.800" pt="5" pb="5">I can honestly affirm that one of my main soft skill is good
                    communication. Working as an assistant professor allowed me to develop this skill even more. I
                    highly enjoy working with other people and to carefully hear all of them.</Text>
                <Text color="gray.800" pb="5">I am always open to criticism, and I always avoid giving critics that cannot be used as a growing
                    mechanism. When I provide feedbacks it to help someone to improve. I am a very friendly person and
                    open to hear and accept good ideas.
                </Text>
            </Box>

            <Box>
                <Heading size="sm">Lifelong Learner</Heading>
                <Text color="gray.800" pt="5" pb="5">Learning new skills and competences is a constant in my life. As a
                    researcher, I am always reading research papers to be updated with state-of-the-art technology in
                    artificial intelligence, deep learning and security. This is one of main reasons I enjoy a lot being
                    part of several program committees of acclaimed conferences from my research domains.</Text>

                <Text color="gray.800" pb="5">As a developer, I am always looking for new frameworks and
                    solutions for my research and software projects.
                </Text>
            </Box>

        </SimpleGrid>
    )
}

export default SoftSkills;
