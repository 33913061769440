import React from 'react';
import {
    Badge,
    Box,
    Flex,
    Heading,
    HStack,
    Image,
    Link,
    List,
    ListItem,
    Grid,
    GridItem,
    SimpleGrid,
    Text,
    VStack
} from '@chakra-ui/react';
import {ChevronRightIcon} from "@chakra-ui/icons";

function Professional() {
    return (
        <SimpleGrid columns={1} spacing={[1, 2, 4]} width="100%">
            <Box borderWidth="1px" borderRadius="md" p={["3", "5", "7"]}>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: 'repeat(8, 1fr)', // Large screens and above
                }} gap={[1, 2, 4]} pb={[3, 1, 1]}>
                    <GridItem colSpan={[1, 1, 6]}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Image src="images/lu_logo.svg" boxSize="100px"/>
                                <VStack align="start">
                                    <Heading size="sm">Leiden University, Leiden, The Netherlands</Heading>
                                    <Text color="gray.700">Assistant Professor</Text>
                                </VStack>
                            </HStack>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={[1, 1, 2]} textAlign={["left", "left", "right"]}>
                        <Text fontWeight="bold">December 2022 - Present</Text>
                    </GridItem>
                </Grid>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: "1fr", // Large screens and above
                }} gap={[1, 2, 4]}>
                    <GridItem colSpan={[1, 1, 1]}>
                        <VStack align="start" mt={2} spacing={2}>
                            <Text fontSize="lg" fontWeight="bold">Responsibilities and
                                Achievements:</Text>
                            <List spacing={2}>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Assistant professor at Faculty of Science
                                    (security,
                                    computer
                                    science and artificial intelligence courses);
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Supervision of PhD, master, and bachelor
                                    students;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Active researching on deep learning and machine
                                    learning techniques
                                    with applications to security;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Committee member of international conferences
                                    (CHES,
                                    DAC, DATE, CARDIS, SPACE, AIHWS);
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Reviewer of security and machine learning
                                    journals;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Published papers in international journals and
                                    conferences;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Collaborated with other faculties and
                                    universities to
                                    develop
                                    interdisciplinary learning opportunities that enhanced student
                                    experience;
                                </ListItem>
                            </List>

                            <Text fontSize="lg" fontWeight="bold">Teaching Courses</Text>
                            <List spacing={2}>
                                <ListItem>
                                    <HStack>
                                        <ChevronRightIcon color="black.500"/>
                                        <Text>Essentials in Computer Systems (digital design, computer architectures,
                                            operating
                                            systems, computer networks)</Text>
                                        <Badge colorScheme="red"><Link
                                            href="https://studiegids.universiteitleiden.nl/courses/116579/essentials-of-computer-systems"
                                            target="_blank">URL</Link></Badge>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack>
                                        <ChevronRightIcon color="black.500"/>
                                        <Text>Cryptographic Engineering</Text>
                                        <Badge colorScheme="red"><Link
                                            href="https://studiegids.universiteitleiden.nl/courses/105167/cryptographic-engineering"
                                            target="_blank">URL</Link></Badge>
                                    </HStack>
                                </ListItem>
                            </List>
                        </VStack>
                    </GridItem>
                </Grid>
            </Box>

            <Box borderWidth="1px" borderRadius="md" p={["3", "5", "7"]}>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: 'repeat(8, 1fr)', // Large screens and above
                }} gap={[1, 2, 4]} pb={[3, 1, 1]}>
                    <GridItem colSpan={[1, 1, 6]}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Image src="images/ru_logo.png" boxSize="100px"/>
                                <VStack align="start">
                                    <Heading size="sm">Radboud University, Nijmegen, The
                                        Netherlands</Heading>
                                    <Text color="gray.700">Postdoctoral Researcher</Text>
                                </VStack>
                            </HStack>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={[1, 1, 2]} textAlign={["left", "left", "right"]}>
                        <Text fontWeight="bold">March 2022 - December 2022</Text>
                    </GridItem>
                </Grid>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: "1fr", // Large screens and above
                }} gap={[1, 2, 4]}>
                    <GridItem colSpan={[1, 1, 1]}>
                        <VStack align="start" mt={2} spacing={2}>
                            <Text fontSize="lg" fontWeight="bold">Responsibilities and
                                Achievements:</Text>
                            <List spacing={2}>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Deep learning and machine learning in the areas
                                    of:
                                    <List ml={4} spacing={1}>
                                        <ListItem><ChevronRightIcon color="black.500"/>Generative AI;</ListItem>
                                        <ListItem><ChevronRightIcon color="black.500"/>Neural Architecture Search and
                                            hyperparameter
                                            optimization (Bayesian Optimization, Reinforcement
                                            Learning);</ListItem>
                                        <ListItem><ChevronRightIcon color="black.500"/>Interpretable AI, Explainable
                                            AI;</ListItem>
                                        <ListItem><ChevronRightIcon color="black.500"/>Regularization
                                            techniques;</ListItem>
                                        <ListItem><ChevronRightIcon color="black.500"/>HPC deployment of deep learning
                                            training;</ListItem>
                                    </List>
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Data mining;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Side-channel analysis in symmetric and
                                    asymmetric
                                    cryptographic
                                    implementations;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Security of AI (adversarial attacks);
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>AI hardware security;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>PhD and Master thesis supervision.
                                </ListItem>
                            </List>
                        </VStack>
                    </GridItem>
                </Grid>
            </Box>

            <Box borderWidth="1px" borderRadius="md" p={["3", "5", "7"]}>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: 'repeat(8, 1fr)', // Large screens and above
                }} gap={[1, 2, 4]} pb={[3, 1, 1]}>
                    <GridItem colSpan={[1, 1, 6]}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Image src="images/tu_logo.png" boxSize="120px"/>
                                <VStack align="start">
                                    <Heading size="sm">Delft University of Technology, Delft, The
                                        Netherlands</Heading>
                                    <Text color="gray.700">Postdoctoral Researcher</Text>
                                </VStack>
                            </HStack>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={[1, 1, 2]} textAlign={["left", "left", "right"]}>
                        <Text fontWeight="bold">March 2020 - March 2022</Text>
                    </GridItem>
                </Grid>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: "1fr", // Large screens and above
                }} gap={[1, 2, 4]}>
                    <GridItem colSpan={[1, 1, 1]}>
                        <VStack align="start" mt={2} spacing={2}>
                            <Text fontSize="lg" fontWeight="bold">Responsibilities and
                                Achievements:</Text>
                            <List spacing={2}>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Deep learning and machine learning in the areas
                                    of:
                                    <List ml={4} spacing={1}>
                                        <ListItem><ChevronRightIcon color="black.500"/>Generative AI;</ListItem>
                                        <ListItem><ChevronRightIcon color="black.500"/>Neural Architecture Search and
                                            hyperparameter
                                            optimization (Bayesian Optimization, Reinforcement
                                            Learning);</ListItem>
                                        <ListItem><ChevronRightIcon color="black.500"/>Interpretable AI, Explainable
                                            AI;</ListItem>
                                        <ListItem><ChevronRightIcon color="black.500"/>Regularization
                                            techniques;</ListItem>
                                        <ListItem><ChevronRightIcon color="black.500"/>HPC deployment of deep learning
                                            training;</ListItem>
                                    </List>
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Data mining;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Side-channel analysis in symmetric and
                                    asymmetric
                                    cryptographic
                                    implementations;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Security of AI (adversarial attacks);
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>AI hardware security;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>PhD and Master thesis supervision.
                                </ListItem>
                            </List>
                        </VStack>
                    </GridItem>
                </Grid>
            </Box>

            <Box borderWidth="1px" borderRadius="md" p={["3", "5", "7"]}>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: 'repeat(8, 1fr)', // Large screens and above
                }} gap={[1, 2, 4]} pb={[3, 1, 1]}>
                    <GridItem colSpan={[1, 1, 6]}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Image src="images/riscure_logo.png" boxSize="120px"/>
                                <VStack align="start">
                                    <Heading size="sm">Riscure B.V., Delft, The Netherlands</Heading>
                                    <Text color="gray.700">Senior Security Analyst</Text>
                                </VStack>
                            </HStack>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={[1, 1, 2]} textAlign={["left", "left", "right"]}>
                        <Text fontWeight="bold">June 2014 - February 2020</Text>
                    </GridItem>
                </Grid>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: "1fr", // Large screens and above
                }} gap={[1, 2, 4]}>
                    <GridItem colSpan={[1, 1, 1]}>
                        <VStack align="start" mt={2} spacing={2}>
                            <Text fontSize="lg" fontWeight="bold">Responsibilities and
                                Achievements:</Text>
                            <List spacing={2}>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Research and development in deep learning for
                                    applications in security assessments of integrated circuits.
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Deep learning framework implementation for
                                    commercial
                                    software;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Side-channel analysis in symmetric and
                                    asymmetric
                                    cryptographic implementations;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Fault Injection;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>System-on-Chip (Soc), Crypto Libraries,
                                    SmartCards,
                                    PayTV system security evaluations;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>IP crypto design evaluation (source code and
                                    testing);
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Vulnerability analysis for certification
                                    process
                                    (EMVCo, Common Criteria)
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Team leader in commercial projects;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Bootloaders, symmetric and public-key
                                    cryptography
                                    source code evaluator;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Signal acquisition and processing;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Statistics and pattern recognition;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>R&D activities, including supervision of master
                                    and
                                    internship students in research projects;
                                </ListItem>
                            </List>
                        </VStack>
                    </GridItem>
                </Grid>
            </Box>

        </SimpleGrid>
    )
}

export default Professional;
