import React, {useState} from 'react';
import {Tooltip, IconButton, useClipboard, Box} from '@chakra-ui/react';
import {MdContentCopy} from 'react-icons/md';

const CopyTextButton = ({textToCopy, closeSection}) => {
    const {hasCopied, onCopy} = useClipboard(textToCopy);
    const [isTooltipOpen, setTooltipOpen] = useState(false);

    const handleCopy = () => {
        onCopy();
        setTooltipOpen(true);

        // Optional: Close the section after copying
        if (closeSection) {
            // Implement your logic to close the section
            console.log('Closing section...');
        }
    };

    return (
        <Box>
            <Tooltip isOpen={isTooltipOpen} label={hasCopied ? 'Copied!' : ''}>
                <IconButton
                    aria-label="Copy Text"
                    icon={<MdContentCopy/>}
                    onClick={handleCopy}
                    mr="1"
                    fontSize="16px"
                    bg="#f9f9f9"
                    color="#444444"
                />
            </Tooltip>
        </Box>
    );
};

export default CopyTextButton;
