import React, {useState} from 'react';
import './App.css';
import {
    Box,
    Container,
    Heading,
    Text,
    Badge,
    VStack,
    HStack,
    SimpleGrid,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Avatar,
    Link,
    Icon
} from '@chakra-ui/react';
import About from "./sections/about";
import Expertise from "./sections/expertise";
import SoftSkills from "./sections/softskills";
import PublicationsBox from "./sections/publications";
import Professional from "./sections/professional";
import Education from "./sections/education";
import {FaLinkedin, FaGraduationCap} from "react-icons/fa"; // Import the required icons


function App() {

    const [tabIndex, setTabIndex] = useState(0);

    return (
        <Box>
            <Container maxW="container.xl" p="2">
                <HStack spacing={4}>
                    <Avatar size="xl" name="Your Name" src="images/avatar.jpg"/>
                    <VStack align="start">
                        <Heading mt={[1, 1, 1]} mb={[1, 1, 1]}>Guilherme Perin</Heading>
                        <Text fontSize="lg" color="gray.500">
                            Assistant Professor | Deep Learning & Security Researcher | Software Developer
                        </Text>
                        <HStack>
                            <Link href="https://www.linkedin.com/in/guilherme-perin-472a378/" target="_blank"
                                  rel="noopener noreferrer">
                                <Icon as={FaLinkedin} boxSize={6} color="blue.500"/>
                            </Link>
                            <Link href="https://scholar.google.com/citations?user=-93JGd4AAAAJ&hl=en" target="_blank"
                                  rel="noopener noreferrer">
                                <Icon as={FaGraduationCap} boxSize={6} color="green.500"/>
                            </Link>
                        </HStack>
                    </VStack>
                </HStack>

                <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)} mt={4}>
                    <TabList
                        flexDirection={{
                            base: "column",
                            md: "row"
                        }} // Set direction to column on small screens and row on medium screens and above
                        flexWrap={{
                            base: "nowrap",
                            md: "wrap"
                        }} // Set flex wrap to nowrap on small screens and wrap on medium screens and above
                    >
                        <Tab>About Me</Tab>
                        <Tab>Expertise</Tab>
                        <Tab>Soft Skills</Tab>
                        <Tab>Professional Experience</Tab>
                        <Tab>Education</Tab>
                        <Tab>Software Development</Tab>
                        <Tab>Publications</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <VStack spacing={[1, 2, 4]} align="start">
                                <Heading size="md">About Me</Heading>
                                <About></About>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <VStack spacing={[1, 2, 4]} align="start">
                                <Heading size="md">Expertise</Heading>
                                <Expertise></Expertise>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <VStack spacing={[1, 2, 4]} align="start">
                                <Heading size="md">Soft Skills</Heading>
                                <SoftSkills></SoftSkills>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <VStack spacing={[1, 2, 4]} align="start">
                                <Heading size="md">Professional Experience</Heading>
                                <Professional></Professional>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <VStack spacing={[1, 2, 4]} align="start">
                                <Heading size="md">Education</Heading>
                                <Education></Education>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <VStack spacing={[1, 2, 4]} align="start">
                                <Heading size="md">Software Development</Heading>
                                <SimpleGrid columns={1} spacing={4} width="100%">
                                    <Box borderWidth="1px" borderRadius="md" p="7">
                                        <Heading size="sm">AISY Framework</Heading>
                                        <HStack spacing={3} pt="5" pb="2">
                                            <Badge colorScheme="blue">Python</Badge>
                                            <Badge colorScheme="blue">React</Badge>
                                            <Badge colorScheme="green">HTML</Badge>
                                            <Badge colorScheme="yellow">CSS</Badge>
                                            <Badge colorScheme="purple">JavaScript</Badge>
                                            <Badge colorScheme="grey">Open-Source</Badge>
                                        </HStack>
                                        <Text color="gray.500">Deep Learning-based Side-Channel Analysis
                                            Framework</Text>
                                    </Box>
                                    <Box borderWidth="1px" borderRadius="md" p="7">
                                        <Heading size="sm">SideDL</Heading>
                                        <HStack spacing={3} pt="5" pb="2">
                                            <Badge colorScheme="blue">Python</Badge>
                                            <Badge colorScheme="blue">React</Badge>
                                            <Badge colorScheme="green">HTML</Badge>
                                            <Badge colorScheme="yellow">CSS</Badge>
                                            <Badge colorScheme="purple">JavaScript</Badge>
                                            <Badge colorScheme="grey">Commercial Software</Badge>
                                        </HStack>
                                        <Text color="gray.500">AI-based Side-Channel Analysis Software</Text>
                                    </Box>
                                    <Box borderWidth="1px" borderRadius="md" p="7">
                                        <Heading size="sm">GateLogica</Heading>
                                        <HStack spacing={3} pt="5" pb="2">
                                            <Badge colorScheme="blue">React</Badge>
                                            <Badge colorScheme="green">HTML</Badge>
                                            <Badge colorScheme="yellow">CSS</Badge>
                                            <Badge colorScheme="purple">JavaScript</Badge>
                                            <Badge colorScheme="grey">Commercial Software</Badge>
                                        </HStack>
                                        <Text color="gray.500">Logic Circuits Simulator</Text>
                                    </Box>
                                </SimpleGrid>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <VStack spacing={[1, 2, 4]} align="start">
                                <Heading size="md">Publications</Heading>
                                <PublicationsBox></PublicationsBox>
                            </VStack>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </Box>

    );
}

export default App;
