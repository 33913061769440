import React from 'react';
import {Badge, Box, Heading, HStack, SimpleGrid, Text} from '@chakra-ui/react';

function Expertise() {
    return (
        <SimpleGrid columns={1} spacing={4} width="100%">
            <Box>
                <Heading size="sm">Artificial Intelligence, Data Science and Security</Heading>
                <Text color="gray.800" pt="5" pb="5">Over the past seven years, I have been deeply involved in
                    the integration of deep neural networks and security fields. Beginning in
                    2016, I was entrusted with developing advanced security solutions for
                    commercial products, gaining extensive experience with frameworks like
                    Keras, Tensorflow, Pytorch, Sklearn, and others. I worked on diverse
                    projects involving deep learning, hyperparameter tuning by handling large
                    datasets. This enriched my skills in data mining and preprocessing,
                    visualization, model optimization, and model deployment over real-world
                    applications.</Text>

                <Text color="gray.800" pb="5">My journey into security commenced in 2009, evolving into a focus
                    on
                    side-channel analysis by 2014. It resulted in a substantial publication
                    record in renowned conferences and journals, covering areas such as
                    generative models, transfer learning, reinforcement learning and
                    hyperparameter tuning.</Text>

                <Text color="gray.800" pb="5">Today, I consider myself an expert in these domains, confident in
                    my ability to apply my knowledge to solve complex problems across various fields.</Text>
            </Box>

            <Box>
                <Heading size="sm">Hardware security, Microelectronics and Cryptography</Heading>
                <Text color="gray.800" pt="5" pb="5">From 2009 to 2014, as part of my research goals during my Master
                    and PhD programs, I focused on researching and developing secure cryptographic designs against
                    hardware attacks. Specifically, I developed public-key architectures against side-channel analysis.
                    These experiences gave broad knowledge in different fields such as microelectronics, FPGA
                    prototyping, embedded electronics, security, cryptography and machine learning.</Text>
                <Text color="gray.800" pb="5">Building on this foundation, I joined Riscure as a security analyst
                    in 2014, evaluating software and hardware devices for global chip manufacturers. I worked on a very
                    dynamic environment and acquired good software skills, bridging academic knowledge with industry
                    practices. Working closely with certification schemes, I followed strict guidelines for source code
                    and design analysis, engaging in project prototyping, report writing, and result analysis. In
                    various projects, I took on leadership roles, enhancing my communication and management
                    skills.</Text>
            </Box>

            <Box>
                <Heading size="sm">Software Development and Deep Learning</Heading>
                <Text color="gray.800" pt="5" pb="5">After 2016, I collaborated on a software development project,
                    bridging deep learning and security assessment for large-scale datasets. Utilizing Python and Java
                    frameworks, I implemented state-of-the-art deep learning frameworks, gaining expertise in
                    optimization techniques like hyperparameter tuning, transfer learning, and interpretability. The
                    developed frameworks were integrated into commercial software, benefiting customers globally.</Text>
                <Text color="gray.800" pb="5">In 2020, upon returning to academia, I kept focusing on software
                    development, creating an open-source framework for applying deep learning to security assessments
                    (side-channel analysis). This framework has been widely adopted by universities, facilitating easy
                    prototyping of novel ideas by students and researchers. These experiences enhanced my
                    problem-solving, programming, and prototyping skills, contributing significantly to my professional
                    growth.</Text>
            </Box>

        </SimpleGrid>
    )
}

export default Expertise;
