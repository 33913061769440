import {Box, Text, VStack, Divider, Badge, Link, HStack} from "@chakra-ui/react";
import {AiOutlineFilePdf, AiOutlineCode} from 'react-icons/ai';
import React from "react";
import CopyTextButton from "./copytext";

const PublicationsBox = () => {
    return (
        <Box borderWidth="1px" borderRadius="md" p={4} mb={4}>
            <VStack align="start" spacing={4}>
                <PublicationItem
                    label_journal="Journal (ACM)"
                    authors="Stjepan Picek, Guilherme Perin, Luca Mariot, Lichao Wu, Lejla Batina"
                    title="SoK: Deep learning-based physical side-channel analysis"
                    journal="ACM Computing Surveys"
                    year="2023"
                    volume="55(11)"
                    pages="p.1-35"
                    url="https://dl.acm.org/doi/pdf/10.1145/3569577"
                />

                <PublicationItem
                    label_journal="Journal (IEEE Transactions)"
                    authors="Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="I Choose You: Automated Hyperparameter Tuning for Deep Learning-based Side-Channel Analysis"
                    journal="IEEE Transactions on Emerging Topics in Computing"
                    year="2022"
                    pages="p.1-12"
                    url="https://ieeexplore.ieee.org/document/9940958"
                    code="https://github.com/AISyLab/AutoSCA"
                />

                <PublicationItem
                    label_journal="Journal (TCHES)"
                    authors="Guilherme Perin, Lichao Wu, Stjepan Picek"
                    title="Exploring Feature Selection Scenarios for deep learning-based side-channel analysis"
                    journal="IACR Transactions on Cryptographic Hardware and Embedded Systems"
                    year="2022"
                    number="4"
                    pages="p.828-861"
                    url="https://tches.iacr.org/index.php/TCHES/article/view/9842/9345"
                    code="https://github.com/AISyLab/feature_selection_dlsca"
                />

                <PublicationItem
                    label_journal="Journal (TCHES)"
                    authors="Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="The Best of Two Worlds: Deep Learning-assisted Template Attack"
                    journal="IACR Transactions on Cryptographic Hardware and Embedded Systems"
                    year="2022"
                    number="3"
                    pages="pp.413-437"
                    url="https://tches.iacr.org/index.php/TCHES/article/view/9707/9237"
                    code="https://github.com/AISyLab/Triplet-attack"
                />

                <PublicationItem
                    label_journal="Journal (TCHES)"
                    authors="Jorai Rijsdijk, Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="Reinforcement learning for hyperparameter tuning in deep learning-based side-channel analysis"
                    journal="IACR Transactions on Cryptographic Hardware and Embedded Systems"
                    year="2021"
                    number="3"
                    pages="pp.677-707"
                    url="https://tches.iacr.org/index.php/TCHES/article/view/8989/8566"
                    code="https://github.com/AISyLab/Reinforcement-Learning-for-SCA"
                />

                <PublicationItem
                    label_journal="Journal (TCHES)"
                    authors="Guilherme Perin, Łukasz Chmielewski, Lejla Batina, Stjepan Picek"
                    title="Keep it unsupervised: Horizontal attacks meet deep learning"
                    journal="IACR Transactions on Cryptographic Hardware and Embedded Systems"
                    year="2021"
                    number="1"
                    pages="pp.343-372"
                    url="https://tches.iacr.org/index.php/TCHES/article/view/8737/8337"
                    code="https://github.com/AISyLab/IterativeDLFramework"
                />

                <PublicationItem
                    label_journal="Journal (TCHES)"
                    authors="Guilherme Perin, Łukasz Chmielewski, Stjepan Picek"
                    title="Strength in numbers: Improving generalization with ensembles in machine learning-based profiled side-channel analysis"
                    journal="IACR Transactions on Cryptographic Hardware and Embedded Systems"
                    year="2020"
                    number="4"
                    pages="pp.337-364"
                    url="https://tches.iacr.org/index.php/TCHES/article/view/8686/8245"
                    code="https://github.com/AISyLab/EnsembleSCA"
                />

                <PublicationItem
                    label_conf="Conference (SAC)"
                    authors="Guilherme Perin, Stjepan Picek"
                    title="On the influence of optimizers in deep learning-based side-channel analysis"
                    journal="Selected Areas in Cryptography (SAC)"
                    year="2020"
                    pages="pp.615–636"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-81652-0_24"
                />

                <PublicationItem
                    label_journal="Journal (IEEE Transactions)"
                    authors="Lichao Wu, Yoo-Seung Won, Dirmanto Jap, Guilherme Perin, Shivam Bhasin, Stjepan Picek"
                    title="Ablation Analysis for Multi-device Deep Learning-based Physical Side-channel Analysis"
                    journal="IEEE Transactions on Dependable and Secure Computing"
                    year="2023"
                    pages="pp.1-12"
                    url="https://ieeexplore.ieee.org/abstract/document/10130619"
                />

                <PublicationItem
                    label_journal="Journal (IEEE Transactions)"
                    authors="Lichao Wu, Leo Weissbart, Marina Krcek, Huimin Li, Guilherme Perin, Lejla Batina, Stjepan Picek"
                    title="Label Correlation in Deep Learning-based Side-channel Analysis"
                    journal="IEEE Transactions on Information Forensics and Security"
                    year="2023"
                    volume="18"
                    pages="pp 3849-3861"
                    url="https://ieeexplore.ieee.org/abstract/document/10155470"
                />

                <PublicationItem
                    label_conf="Conference (DSN)"
                    authors="Xiaoyun Xu, Guilherme Perin, Stjepan Picek"
                    title="IB-RAR: Information Bottleneck as Regularizer for Adversarial Robustness"
                    journal="53rd Annual IEEE/IFIP International Conference on Dependable Systems and Networks Workshops (DSN-W),"
                    year="2023"
                    pages="pp.129-135"
                    url="https://ieeexplore.ieee.org/document/10207164"
                />

                <PublicationItem
                    label_chapter="Book Chapter (Springer)"
                    authors="Guilherme Perin, Lichao Wu, Stjepan Picek"
                    title="Gambling for Success: The Lottery Ticket Hypothesis in Deep Learning-Based Side-Channel Analysis"
                    journal="Artificial Intelligence for Cybersecurity"
                    publisher="Advances in Information Security, vol 54. Springer, Cham"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-97087-1_9"
                    code="https://github.com/AISyLab/lth_sca"
                />

                <PublicationItem
                    label_journal="Journal (Springer)"
                    authors="Marina Krček, Guilherme Perin"
                    title="Autoencoder-enabled Model Portability for Reducing Hyperparameter Tuning Efforts in Side-channel Analysis"
                    journal=" Journal of Cryptographic Engineering "
                    year="2023"
                    url="https://link.springer.com/article/10.1007/s13389-023-00330-4"
                />

                <PublicationItem
                    label_journal="Journal (Springer)"
                    authors="Maikel Kerkhof, Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="No (good) loss no gain: systematic evaluation of loss functions in deep learning-based side-channel analysis."
                    journal=" Journal of Cryptographic Engineering "
                    year="2023"
                    url="https://link.springer.com/article/10.1007/s13389-023-00320-6"
                />

                <PublicationItem
                    authors="Azade Rezaeezade, Abraham Basurto-Becerra, Léo Weissbart, Guilherme Perin"
                    title="One for All, All for Ascon: Ensemble-based Deep Learning Side-channel Analysis"
                    journal="IACR Cryptology ePrint Archive"
                    year="2023"
                    number="1922"
                    url="https://eprint.iacr.org/2023/1922.pdf"
                />

                <PublicationItem
                    authors="Ioana Savu, Marina Krček, Guilherme Perin, Lichao Wu, Stjepan Picek"
                    title="The Need for MORE: Unsupervised Side-channel Analysis with Single Network Training and Multi-output Regression"
                    journal="IACR Cryptology ePrint Archive"
                    year="2023"
                    number="1681"
                    url="https://eprint.iacr.org/2023/1681.pdf"
                />

                <PublicationItem
                    authors="Huimin Li, Guilherme Perin"
                    title="A Systematic Study of Data Augmentation for Protected AES Implementations"
                    journal="IACR Cryptology ePrint Archive"
                    year="2023"
                    number="1179"
                    url="https://eprint.iacr.org/2023/1179.pdf"
                />

                <PublicationItem
                    authors="Lichao Wu, Sébastien Tiran, Guilherme Perin, Stjepan Picek"
                    title="An end-to-end plaintext-based side-channel collision attack without trace segmentation"
                    journal="IACR Cryptology ePrint Archive"
                    year="2023"
                    number="1109"
                    url="https://eprint.iacr.org/2023/1109.pdf"
                />

                <PublicationItem
                    authors="Lichao Wu, Amir Ali-pour, Azade Rezaeezade, Guilherme Perin, Stjepan Picek"
                    title="Breaking Free: Leakage Model-free Deep Learning-based Side-channel Analysis"
                    journal="IACR Cryptology ePrint Archive"
                    year="2023"
                    number="1110"
                    url="https://eprint.iacr.org/2023/1110.pdf"
                />

                <PublicationItem
                    authors="Sengim Karayalcin, Marina Krcek, Lichao Wu, Stjepan Picek, Guilherme Perin"
                    title="It's a Kind of Magic: A Novel Conditional GAN Framework for Efficient Profiling Side-channel Analysis"
                    journal="IACR Cryptology ePrint Archive"
                    year="2023"
                    number="1108"
                    url="https://eprint.iacr.org/2023/1108.pdf"
                />

                <PublicationItem
                    authors="Marina Krček, Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="Shift-invariance Robustness of Convolutional Neural Networks in Side-channel Analysis"
                    journal="IACR Cryptology ePrint Archive"
                    year="2023"
                    number="1100"
                    url="https://eprint.iacr.org/2023/1100.pdf"
                />

                <PublicationItem
                    authors="Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="Not so Difficult in the End: Breaking the Lookup Table-based Affine Masking Scheme"
                    journal="IACR Cryptology ePrint Archive"
                    year="2023"
                    number="724"
                    url="https://eprint.iacr.org/2023/724.pdf"
                />

                <PublicationItem
                    authors="Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="Hiding in Plain Sight: Non-profiling Deep Learning-based Side-channel Analysis with Plaintext/Ciphertext"
                    journal="IACR Cryptology ePrint Archive"
                    year="2023"
                    number="209"
                    url="https://eprint.iacr.org/2023/209.pdf"
                />

                <PublicationItem
                    label_conf="Conference (ICECS)"
                    authors="Rodrigo Lellis, Rafael Soares, Guilherme Perin"
                    title="Pruning-based Neural Network Reduction for Faster Profiling Side-Channel Attacks"
                    journal="29th IEEE International Conference on Electronics, Circuits and Systems (ICECS)"
                    year="2022"
                    number="209"
                    pages="pp. 1-4"
                    url="https://ieeexplore.ieee.org/abstract/document/9971033"
                />

                <PublicationItem
                    label_conf="Conference (CARDIS)"
                    authors="Stjepan Picek, Annelie Heuser, Guilherme Perin, Sylvain Guilley"
                    title="Profiled side-channel analysis in the efficient attacker framework"
                    journal="International Conference on Smart Card Research and Advanced Applications (CARDIS)"
                    year="2021"
                    pages="pp.44-63"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-97348-3_3"
                />

                <PublicationItem
                    label_chapter="Book Chapter (Springer)"
                    authors="Marina Krcek, Huimin Li, Servio Paguada, Unai Rioja, Lichao Wu, Guilherme Perin, Lukasz Chmielewski"
                    title="Deep Learning on Side-Channel Analysis"
                    journal="Security and Artificial Intelligence"
                    volume="13049"
                    year="2022"
                    publisher="Lecture Notes in Computer Science, Springer, Cham"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-98795-4_3"
                />

                <PublicationItem
                    label_conf="Conference (ANCS)"
                    authors="Sudharshan Swaminathan, Łukasz Chmielewski, Guilherme Perin, Stjepan Picek"
                    title="Deep learning-based side-channel analysis against aes inner rounds"
                    journal="Applied Cryptography and Network Security Workshops. ACNS"
                    year="2022"
                    publisher="Lecture Notes in Computer Science, Springer, Cham"
                    url="https://link.springer.com/chapter/10.1007/978-3-031-16815-4_10"
                />

                <PublicationItem
                    authors="Guilherme Perin, Lichao Wu, Stjepan Picek"
                    title="I know what your layers did: Layer-wise explainability of deep learning side-channel analysis"
                    journal="IACR Cryptology ePrint Archive"
                    year="2022"
                    number="1087"
                    url="https://eprint.iacr.org/2022/1087.pdf"
                />

                <PublicationItem
                    label_journal="Journal (MDPI)"
                    authors="Guilherme Perin, Lichao Wu, Stjepan Picek"
                    title="The Need for Speed: A Fast Guessing Entropy Calculation for Deep Learning-Based SCA"
                    journal="Algorithms"
                    year="2023"
                    volume="16(3)"
                    pages="p.127"
                    url="https://www.mdpi.com/1999-4893/16/3/127"
                    code="https://github.com/AISyLab/fge_sca"
                />

                <PublicationItem
                    label_journal="Journal (MDPI)"
                    authors="Sengim Karayalcin, Guilherme Perin, Stjepan Picek"
                    title="Resolving the doubts: On the construction and use of resnets for side-channel analysis"
                    journal="Mathematics"
                    year="2023"
                    volume="11(15)"
                    url="https://www.mdpi.com/2227-7390/11/15/3265"
                />

                <PublicationItem
                    label_conf="Conference (AFRICACRYPT)"
                    authors="Azade Rezaeezade, Guilherme Perin, Stjepan Picek"
                    title="To Overfit or Not to Overfit: Improving the Performance of Deep Learning-Based SCA"
                    journal="Progress in Cryptology - AFRICACRYPT 2022: 13th International Conference on Cryptology in Africa"
                    year="2022"
                    publisher="Lecture Notes in Computer Science"
                    pages="p.397-421"
                    url="https://link.springer.com/chapter/10.1007/978-3-031-17433-9_17"
                />

                <PublicationItem
                    label_conf="Conference (COSADE)"
                    authors="Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="On the Evaluation of Deep Learning-Based Side-Channel Analysis"
                    journal="Constructive Side-Channel Analysis and Secure Design - 13th International Workshop, COSADE"
                    year="2022"
                    publisher="Lecture Notes in Computer Science"
                    volume="13211"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-99766-3_3"
                />

                <PublicationItem
                    label_conf="Conference (COSADE)"
                    authors="Maikel Kerkhof, Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="Focus is Key to Success: A Focal Loss Function for Deep Learning-Based Side-Channel Analysis"
                    journal="Constructive Side-Channel Analysis and Secure Design - 13th International Workshop, COSADE"
                    year="2022"
                    publisher="Lecture Notes in Computer Science"
                    volume="13211"
                    pages="pp 29-48"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-99766-3_2"
                />

                <PublicationItem
                    label_conf="Conference (ACNS)"
                    authors="Huimin Li, Marina Krcek, Guilherme Perin"
                    title="Comparison of Weight Initializers in Deep Learning-based Side-channel Analysis"
                    journal="ACNS 2020: Applied Cryptography and Network Security Workshops"
                    year="2020"
                    pages="pp 126–143"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-61638-0_8"
                />

                <PublicationItem
                    label_conf="Conference (ACNS)"
                    authors="Lichao Wu, Guilherme Perin"
                    title="On the importance of Pooling Layers Tuning for Profiled Side-Channel Analysis"
                    journal="Applied Cryptography and Network Security Workshops, ACNS"
                    year="2021"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-81645-2_8"
                />

                <PublicationItem
                    label_conf="Conference (SPACE)"
                    authors="Jorai Rijsdijk, Lichao Wu, Guilherme Perin, Stjepan Picek"
                    title="Reinforcement learning-based design of side-channel countermeasures"
                    journal="International Conference on Security, Privacy, and Applied Cryptography Engineering"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-95085-9_9"
                    year="2022"
                />

                <PublicationItem
                    label_conf="Conference (COSADE)"
                    authors="Guilherme Perin, Ileana Buhan, Stjepan Picek"
                    title="Learning when to stop: a mutual information approach to fight overfitting in profiled side-channel analysis"
                    journal="Constructive Side-Channel Analysis and Secure Design (COSADE)"
                    year="2020"
                    number="58"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-89915-8_3"
                />

                <PublicationItem
                    authors="Guilherme Perin, Lichao Wu, Stjepan Picek"
                    title="Aisy-deep learning-based framework for side-channel analysis"
                    journal="IACR Cryptology ePrint Archive"
                    year="2021"
                    number="357"
                    url="https://eprint.iacr.org/2021/357.pdf"
                />

                <PublicationItem
                    authors="Guilherme Perin, Baris Ege, Lukasz Chmielewski"
                    title="Neural Network Model Assessment for Side-Channel Analysis"
                    journal="IACR Cryptology ePrint Archive"
                    year="2019"
                    number="722"
                    url="https://eprint.iacr.org/2019/722.pdf"
                />

                <PublicationItem
                    label_conf="Conference (COSADE)"
                    authors="Guilherme Perin, Laurent Imbert, Lionel Torres, Philippe Maurine"
                    title="Attacking Randomized Exponentiations Using Unsupervised Learning"
                    journal="COSADE 2014"
                    pages="144-160"
                    url="https://link.springer.com/chapter/10.1007/978-3-319-10175-0_11"
                />

                <PublicationItem
                    label_journal="Journal (Springer)"
                    authors="Guilherme Perin, Laurent Imbert, Philippe Maurine, Lionel Torres"
                    title="Vertical and horizontal correlation attacks on RNS-based exponentiations"
                    journal="Journal of Cryptographic Engineering"
                    year="2015"
                    volume="5(3)"
                    pages="171-185"
                    url="https://link.springer.com/article/10.1007/s13389-015-0095-0"
                />

                <PublicationItem
                    label_conf="Conference (ASIACRYPT)"
                    authors="Christos Andrikos, Lejla Batina, Lukasz Chmielewski, Liran Lerman, Vasilios Mavroudis, Kostas Papagiannopoulos, Guilherme Perin, Giorgos Rassias, Alberto Sonnino"
                    title="Location, Location, Location: Revisiting Modeling and Exploitation for Location-Based Side Channel Leakages"
                    journal="ASIACRYPT (3)"
                    year="2019"
                    pages="285-314"
                    url="https://link.springer.com/chapter/10.1007/978-3-030-34618-8_10"
                />

                <PublicationItem
                    label_conf="Conference (CARDIS)"
                    authors="Guilherme Perin, Łukasz Chmielewski"
                    title="A semi-parametric approach for side-channel attacks on protected RSA implementations"
                    journal="Smart Card Research and Advanced Applications (CARDIS)"
                    year="2015"
                    pages="pp. 34-53"
                    url="https://link.springer.com/chapter/10.1007/978-3-319-31271-2_3"
                />

                <PublicationItem
                    label_conf="Conference (ACISP)"
                    authors="Christophe Nègre, Guilherme Perin"
                    title="Trade-Off Approaches for Leak Resistant Modular Arithmetic in RNS"
                    journal="ACISP 2015"
                    pages="107-124"
                    url="https://link.springer.com/chapter/10.1007/978-3-319-19962-7_7"
                />

                <PublicationItem
                    label_conf="Conference (CARDIS)"
                    authors="Guilherme Perin, Laurent Imbert, Lionel Torres, Philippe Maurine"
                    title="Practical Analysis of RSA Countermeasures Against Side-Channel Electromagnetic Attacks"
                    journal="CARDIS 2013"
                    pages="200-215"
                    url="https://link.springer.com/chapter/10.1007/978-3-319-08302-5_14"
                />

                <PublicationItem
                    label_conf="Conference (DSD)"
                    authors="Guilherme Perin, Laurent Imbert, Lionel Torres, Philippe Maurine"
                    title="Electromagnetic Analysis on RSA Algorithm Based on RNS"
                    journal="DSD 2013"
                    pages="345-352"
                    url="Electromagnetic Analysis on RSA Algorithm Based on RNS"
                />

                <PublicationItem
                    label_conf="Conference (DSD)"
                    authors="Guilherme Perin, Lionel Torres, Pascal Benoit, Philippe Maurine"
                    title="Amplitude demodulation-based EM analysis of different RSA implementations"
                    journal="DATE 2012"
                    pages="1167-1172"
                    url="https://ieeexplore.ieee.org/abstract/document/6176670"
                />

                <PublicationItem
                    label_journal="Journal (RITA)"
                    authors="Guilherme Perin, Alice J. Kozakevicius"
                    title="Filtragem Wavelet de Sinais Cardíacos através de Algoritmos Adaptativos"
                    journal="RITA"
                    year="2013"
                    volume="20(3)"
                    pages="95-111"
                    url="https://seer.ufrgs.br/index.php/rita/article/view/PERIN-RITA-VOL20-NR3/27345"
                />

                <PublicationItem
                    label_journal="Journal (IJRC)"
                    authors="Guilherme Perin, Daniel Gomes Mesquita, João Baptista Martins"
                    title="Montgomery modular multiplication on reconfigurable hardware: Systolic versus multiplexed implementation"
                    journal="International Journal of Reconfigurable Computing"
                    year="2011"
                    url="https://www.hindawi.com/journals/ijrc/2011/127147/"
                />
            </VStack>
        </Box>
    );
};

const PublicationItem = ({
                             label_journal,
                             label_conf,
                             label_chapter,
                             authors,
                             title,
                             journal,
                             year,
                             volume,
                             pages,
                             number,
                             publisher,
                             url,
                             code
                         }) => {
    return (
        <VStack align="start" spacing={1}>
            <HStack><Text fontWeight="bold">{title}</Text>
                <CopyTextButton textToCopy={title} closeSection={true}/>
            </HStack>
            {
                label_journal && <Badge colorScheme="yellow">{label_journal}</Badge>
            }
            {
                label_conf && <Badge colorScheme="orange">{label_conf}</Badge>
            }
            {
                label_chapter && <Badge colorScheme="green">{label_chapter}</Badge>
            }
            <Text fontStyle="italic">{authors}</Text>
            <Text>
                {journal}, {year}
                {volume && `, ${volume}`}
                {number && `, number ${number}`}
                {pages && `, ${pages}`}
            </Text>
            {publisher && <Text>{publisher}</Text>}
            <HStack spacing={3}>
                {url && <Badge colorScheme="red" mt="1" p="1">
                    <Link href={url} target="_blank" display="flex" alignItems="center">
                        <Box mr="1">
                            <AiOutlineFilePdf/>
                        </Box>
                        pdf
                    </Link>
                </Badge>}
                {code && <Badge colorScheme="blue" mt="1" p="1">
                    <Link href={code} target="_blank" display="flex" alignItems="center">
                        <Box mr="1">
                            <AiOutlineCode/>
                        </Box>
                        Source Code
                    </Link>
                </Badge>}
            </HStack>
            <Divider bg="grey.500" h="2px"/>
        </VStack>
    );
};

export default PublicationsBox;
