import React from 'react';
import {Text} from '@chakra-ui/react';
import App from "../App";

function About() {
    return (
        <Text>
            I am an Assistant Professor, researcher and developer working on the fields of data analysis, artificial
            intelligence and security. With 14 years of experience as a researcher and developer, my primary focus has
            been on applying deep learning and machine learning techniques to cybersecurity. I possess extensive
            knowledge and expertise in areas such as data mining, deep learning, supervised and unsupervised learning,
            side-channel analysis, cryptography, algorithms, software development, and research project management and
            supervision. Alongside my technical proficiency, I also excel in coding and communication skills, enabling
            effective collaboration and dissemination of ideas.
        </Text>
    )
}

export default About;
