import React from 'react';
import {
    Badge,
    Box,
    Flex,
    Heading,
    HStack,
    Image,
    List,
    ListItem,
    SimpleGrid,
    Text,
    VStack,
    Link,
    Grid,
    GridItem
} from '@chakra-ui/react';
import {ChevronRightIcon} from '@chakra-ui/icons'

function Education() {
    return (
        <SimpleGrid columns={1} spacing={[1, 2, 4]} width="100%">
            <Box borderWidth="1px" borderRadius="md" p={["3", "5", "7"]}>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: 'repeat(8, 1fr)', // Large screens and above
                }} gap={[1, 2, 4]} pb={[3, 1, 1]}>
                    <GridItem colSpan={[1, 1, 6]}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Image src="images/um2_logo.png" boxSize="100px" m={[2, 2, 4]}/>
                                <VStack align="start">
                                    <Heading size="sm">PhD in Automated Systems and Microelectronics</Heading>
                                    <Heading size="sm">Laboratoire d’Informatique, de Robotique et de Microélectronique
                                        de
                                        Montpellier (LIRMM), Montpellier, France
                                    </Heading>
                                    <Text display={{base: 'none', sm: "none", md: 'block'}}>Title: On the Resistance of
                                        RSA Countermeasures at Algorithmic, Arithmetic and
                                        Hardware
                                        Levels Against Chosen-Message, Correlation and Single-Execution Side-Channel
                                        Attacks</Text>
                                    <HStack>
                                        <Text>Link to the thesis: </Text>
                                        <Badge colorScheme="red"><Link
                                            href="https://ged.biu-montpellier.fr/florabium/servlet/DocumentFileManager?source=ged&document=ged:IDOCS:308503&resolution=MEDIUM&recordId=theses%3ABIU_THESE%3A2133&file=43161_PERIN_2014_archivage.pdf"
                                            target="_blank">PDF</Link></Badge>
                                    </HStack>
                                </VStack>
                            </HStack>
                        </Flex>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Text display={{base: 'block', sm: "block", md: 'none'}}>Title: On the Resistance of RSA
                                    Countermeasures at Algorithmic, Arithmetic and
                                    Hardware
                                    Levels Against Chosen-Message, Correlation and Single-Execution Side-Channel
                                    Attacks</Text>
                            </HStack>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={[1, 1, 2]} textAlign={["left", "left", "right"]}>
                        <Text fontWeight="bold">June 2011 - May 2014</Text>
                    </GridItem>
                </Grid>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: "1fr", // Large screens and above
                }} gap={[1, 2, 4]}>
                    <GridItem colSpan={[1, 1, 1]}>
                        <VStack align="start" mt={2} spacing={2}>
                            <Text fontSize="lg" fontWeight="bold">Research topics:</Text>
                            <List spacing={2}>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Implementation of public-key cryptographic
                                    algorithms
                                    in software (C) and hardware (VHDL, Verilog);
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Practical side-channel attacks, from signal
                                    acquisition
                                    to attack deployment;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Statistics and pattern recognition for
                                    identification
                                    of security vulnerabilities in integrated circuits (smart cards, System-on-Chips,
                                    FPGA,
                                    micro-controllers);
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Side-channel analysis with unsupervised
                                    learning (on
                                    public-key crypto algorithms)
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Wavelet transform and signal processing;
                                </ListItem>
                            </List>
                        </VStack>
                    </GridItem>
                </Grid>
            </Box>

            <Box borderWidth="1px" borderRadius="md" p={["3", "5", "7"]}>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: 'repeat(8, 1fr)', // Large screens and above
                }} gap={[1, 2, 4]} pb={[3, 1, 1]}>
                    <GridItem colSpan={[1, 1, 6]}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Image src="images/ufsm_logo.jpeg" boxSize="100px"/>
                                <VStack align="start">
                                    <Heading size="sm">MSc in Computer Science</Heading>
                                    <Heading size="sm">Federal University of Santa Maria, Santa Maria-RS,
                                        Brazil</Heading>
                                    <Text display={{base: 'none', sm: "none", md: 'block'}}>Title: Public Key
                                        Cryptography Architectures: Performance and Robustness
                                        Analysis</Text>
                                    <HStack>
                                        <Text>Link to the thesis (in PT-BR): </Text>
                                        <Badge colorScheme="red"><Link
                                            href="https://repositorio.ufsm.br/bitstream/handle/1/5371/PERIN%2c%20GUILHERME.pdf?sequence=1&isAllowed=y"
                                            target="_blank">PDF</Link></Badge>
                                    </HStack>
                                </VStack>
                            </HStack>
                        </Flex>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Text display={{base: 'block', sm: "block", md: 'none'}}>Title: Public Key Cryptography
                                    Architectures: Performance and Robustness</Text>
                            </HStack>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={[1, 1, 2]} textAlign={["left", "left", "right"]}>
                        <Text fontWeight="bold">March 2009 - February 2011</Text>
                    </GridItem>
                </Grid>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: "1fr", // Large screens and above
                }} gap={[1, 2, 4]}>
                    <GridItem colSpan={[1, 1, 1]}>
                        <VStack align="start" mt={2} spacing={2}>
                            <Text fontSize="lg" fontWeight="bold">Research topics:</Text>
                            <List spacing={2}>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>Implementation of RSA public-key cryptographic
                                    algorithm in hardware (VHDL, Verilog);
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>
                                    Side-channel analysis and FPGA implementation of RSA algorithm;
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>
                                    Statistics and pattern recognition for identification of security vulnerabilities in
                                    integrated circuits (FPGA);
                                </ListItem>
                                <ListItem>
                                    <ChevronRightIcon color="black.500"/>
                                    Signal processing, wavelet transform, pattern recognition for signal alignment;
                                </ListItem>
                            </List>
                        </VStack>
                    </GridItem>
                </Grid>
            </Box>

            <Box borderWidth="1px" borderRadius="md" p={["3", "5", "7"]}>
                <Grid templateColumns={{
                    base: "1fr",      // Small screens
                    sm: "1fr", // Medium screens
                    md: 'repeat(8, 1fr)', // Large screens and above
                }} gap={[1, 2, 4]} pb={[3, 1, 1]}>
                    <GridItem colSpan={[1, 1, 6]}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Image src="images/ufsm_logo.jpeg" boxSize="100px"/>
                                <VStack align="start">
                                    <Heading size="sm">BSc in Electrical Engineering</Heading>
                                    <Heading size="sm">Federal University of Santa Maria, Santa Maria-RS,
                                        Brazil</Heading>
                                    <Text display={{base: 'none', sm: "none", md: 'block'}}>Bachelor thesis:
                                        Implementation of an ECG analog filter using CMOS technology</Text>
                                </VStack>
                            </HStack>
                        </Flex>
                        <Flex justifyContent="space-between" alignItems="center">
                            <HStack spacing={[1, 2, 4]} alignItems="center">
                                <Text display={{base: 'block', sm: "block", md: 'none'}}>Bachelor thesis: Implementation
                                    of an ECG analog filter using CMOS technology</Text>
                            </HStack>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={[1, 1, 2]} textAlign={["left", "left", "right"]}>
                        <Text fontWeight="bold">September 2003 - August 2008</Text>
                    </GridItem>
                </Grid>
            </Box>
        </SimpleGrid>
    )
}

export default Education;
